<section class="otp_bg" style="overflow: scroll; height: 103vh">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-6 d-none d-lg-block">
        <div class="left">
          <img
            class="mx-auto d-block pt-5 left_img"
            [nexaeiImageSrc]="'newsignup.png'"
          />
        </div>
      </div>
      <div class="col-md-12 col-lg-6 pt-3">
        <div class="otp-container">
          <span class="logo col-md-12">
            <img class="left_img" [nexaeiImageSrc]="'logo.png'" />
            <h1 class="newsignup_text">Verify your Email Address</h1>
            <div class="newsignup_top"><img width="145px"src="./assets/images/newsignup_icon.png"></div>
            
            <p class="newsignup_text">
              We have sent a confirmation link in your email. Please check your inbox and click on link to verify your email address.
            </p>
            <!-- Modal -->
            <div
              class="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <app-otp-to-mobile></app-otp-to-mobile>
            </div>
          </span>

       

          <span class="bottom-text">
            <label class="login_cndtn redirec_sigin mt-4">
              <h6>Didn't receive the email? Check your spam folder or
             
           
            </h6>
            <h6 class="fontlarger" *ngIf="otpResend">
              Link resend successfully !!
            </h6>
              <h6>
               
                <a
                  class=" "
                  [ngClass]="otpTimeout == false ? 'disabled' : ''"
                  [ngClass]="otpTimeout == true ? 'green' : 'link-secondary'"
                  (click)="resendOTP()"
                  [ngStyle]="otpTimeout == true ? { cursor: 'pointer' } : ''"
                  >Resend Confirmation Link</a
                >
              </h6>
                           

            </label>
          </span>
          <span class="bottom-text">
            <label class="login_cndtn redirec_sigin mt-4">
              <h6>To receive an OTP on your mobile instad,
              <span
                class="text-green"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                style="cursor: pointer"
              >
                click here
              </span>
           
            </h6>
             
            </label>
          </span>

        </div>
      </div>
    </div>
  </div>
</section>