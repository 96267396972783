



import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AuthHttpService } from 'src/app/service/auth-http.service';
import { ValidateService } from 'src/app/service/validate.service';
import { SignupPhaseTwo } from 'src/app/interfaces/signup.interface';
import { resendOTPRequest } from 'src/app/interfaces/signup.interface';
import { StoreService } from 'src/app/service/store.service';
import { CryptoService } from 'src/app/service/crypto.service';
import { JwtService } from 'src/app/service/jwt.service';
import { LoginRequest } from 'src/app/interfaces/signup.interface';
import { CommunicationService } from 'src/app/service/communication.service';

import { timer, Subscription } from 'rxjs';
import { SignupOne } from 'src/app/interfaces/store.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup-verify-pass-new',
  templateUrl: './signup-verify-pass-new.component.html',
  styleUrls: ['./signup-verify-pass-new.component.scss']
})
export class SignupVerifyPassNewComponent implements OnInit {
  registerFormTwo: FormGroup;
  userDetails: string = '';
  //returnUrl: string = 'signup/orgsetup-1';
  returnUrl: string = 'signup/verify-email-new';
  returnUrl2: string = './dashboard/';
  returnUrlBackward: string = 'signup/';
  error: string | null = null;
  isSubmitFormLoading: boolean = false;
  otpSendSuccess: boolean = false;
  otpSendSuccess1: boolean = false;
  email: string;
  hasError: boolean = false;
  btnActive: boolean = false;
  otpResend: boolean = false;

  otpTimeout: boolean = false;
  otpTimeout1: boolean = false;
  frmInvalid: boolean = false;
  countDown: Subscription | null = null;
  counter = 0;
  tick = 1000;
  counter1 = 0;
  tick1 = 1000;
  stopInitialTimer: boolean = false;
  data: string | null = null;
  otp: string;

  signupformOneData: SignupOne | null;
  serverErrorMessage: string | null = null;
  showAlert: boolean = false;
  otpArray = [];
  subscriptionClearOtp$: Subscription;
  
  constructor(
    private authHttp: AuthHttpService,
    private customValidator: ValidateService,
    private router: Router,
    private store: StoreService,
    private encryption: CryptoService,
    private jwt: JwtService,
	private comm: CommunicationService,
  ) {
    this.registerFormTwo = new FormGroup({
      digitOne: new FormControl(null, [Validators.required]),
      digitTwo: new FormControl(null, [Validators.required]),
      digitThree: new FormControl(null, [Validators.required]),
      digitFour: new FormControl(null, [Validators.required]),
      digitFive: new FormControl(null, [Validators.required]),
      digitSix: new FormControl(null, [Validators.required]),
    });
	
	this.subscriptionClearOtp$ = this.comm.clearOtp$.subscribe(
      (data) => { 
		this.otpArray = [];
      }
    );
  }

  ngOnInit(): void {
    this.email = this.store.getEmailFromLocal();
    if (this.registerFormTwo.valid) {
      this.serverErrorMessage = '';
    }
	
	if(localStorage.getItem('email_verification_otp') != null) {
		this.otp = this.encryption.decrypt(
		  localStorage.getItem('email_verification_otp')
		);
		this.otpArray = this.otp.split('');
	}
  }
  ngAfterViewInit() {
    this.activateCountDown();
  }
  activateCountDown() {
    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.counter > 0) {
        --this.counter;
      } else {
        this.otpTimeout = true;
        this.otpSendSuccess = false;
        return false;
      }
    });
  }

  activateCountDown2() {
    this.countDown = timer(0, this.tick1).subscribe(() => {
      if (this.counter1 > 0) {
        --this.counter1;
      } else {
        this.otpTimeout1 = true;
        this.otpSendSuccess1 = false;
        return false;
      }
    });
  }
  get registerFormTwoControl() {
    return this.registerFormTwo.controls;
  }

  getSignupFormOneData(): SignupOne | null {
    let data: string | null = this.store.getSignupFormOneData();
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }
  
  

  login(data: LoginRequest): void {
    this.authHttp.login(data).subscribe(
      (response) => {
        if (response.success) {
          if (response.data) {
            this.jwt.setToken(response.data['token']);
          }
          this.router.navigate([this.returnUrl]);
          return;
        }
        this.data = response.data;
      },
      (error: HttpErrorResponse) => {
        this.data = environment.errorMessage;
      }
    );
  }

  resendOTP() {
    if (this.counter == 0) {
      this.registerFormTwo.reset();
      this.stopInitialTimer = true;
      this.otpTimeout = false;
	  localStorage.removeItem('email_verification_otp');
	  this.otpArray = [];
      let otpData: resendOTPRequest = {
        email: this.store.getEmailFromLocal(),
      };
      this.authHttp.resendOTP(otpData).subscribe(
        (response) => {
		  if(response.success) {
		  this.counter = 120;
          this.isSubmitFormLoading = false;
		  this.otpResend = true;
          this.activateCountDown2();
		  } else {
		  this.serverErrorMessage = response.message;
		  }
        },
        (_) => {
          this.isSubmitFormLoading = false;
          this.serverErrorMessage = environment.errorMessage;
        }
      );
    }
  }

  @ViewChildren('otpInputs') otpInputElements: QueryList<ElementRef> | null =
    null;
  backspaceCount: number = 0;
  toggleFocus(event: KeyboardEvent, index: number) {
    this.serverErrorMessage = '';
    this.otpResend = false;
    let inputElement = this.otpInputElements?.get(index);
    let inputValue = inputElement?.nativeElement.value;

    if (event.key === 'Backspace' && index >= 1 && index <= 5) {
      if (inputValue.length === 0 && this.backspaceCount >= 1) {
        this.otpInputElements?.get(index - 1)?.nativeElement.focus();

        this.backspaceCount = 0;
      } else this.backspaceCount++;
    } else this.backspaceCount = 0;

    if (inputValue.length >= 1 && index >= 0 && index <= 6) {
      this.otpInputElements?.get(index + 1)?.nativeElement.focus();
    }
  }
  onPaste(event) {
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
    }, 2000);
    return false;
  }
  
  ngOnDestroy(): void {
    if (this.subscriptionClearOtp$) this.subscriptionClearOtp$.unsubscribe();
  }
}


