<div class="row">
  <div class="mid-sec d-flex justify-content-center align-items-center">
    <div class="text-center">
      <div><img width="70px" [nexaeiImageSrc]="'emailexpired.png'" /></div>

      <h5 class="s-m pb-2 pt-3">Email Verification Link Expreired</h5>

      <p class="fs-12 d-none d-sm-block">
        Resend the verification link to your email
      </p>
      <p class=" fontlarger" *ngIf="otpResend">
        Link resend successfully !!
      </p>

      <p class="fs-12 d-block d-sm-none">
        Please use the OTP to complete<br />
        your registration. OTP is valid for <br />
        next 30 minutes
      </p>
       <!-- Modal -->
       <div
       class="modal fade"
       id="staticBackdrop"
       data-bs-backdrop="static"
       data-bs-keyboard="false"
       tabindex="-1"
       aria-labelledby="staticBackdropLabel"
       aria-hidden="true"
     >
       <app-otp-to-mobile></app-otp-to-mobile>
     </div>
     <!---- <div class="d-flex justify-content-center">
        <div class="gray-div text-white text-center fs-14">{{ otp }}</div>
      </div>-->

      

      <div class="d-flex justify-content-center">
       
        <div
          class="new_green-div text-white text-center fs-13 d-flex justify-content-center"
          (click)="resendOTP()"
        >
        
          <p>Resend</p>

          <!--<div>
            <img
              class="ps-2"
              width="20px"
              [nexaeiImageSrc]="'rightarrow.png'"
            />
          </div>-->
        </div>
        </div>
        <div><span class="bottom-text">
          <label class="login_cndtn redirec_sigin mt-4">
            <h6>To receive an OTP on your mobile instad,
            <span
              class="text-green"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              style="cursor: pointer"
            >
              click here
            </span>
            
         
          </h6>
           
          </label>
        </span></div>
    </div>
  </div>
</div>